import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Profile() {
  return  (
      <Layout>
      <SEO 
        pagetitle="プロフィール"
        pagedesc="株式会社わくわくスタディワールドの社員プロフィール"
      />      
      <div className="keni-main_wrap">
        <div className="keni-main_outer">
          {/*▼▼ メインコンテンツ ▼▼*/}
          <main id="main" className="keni-main">
            <div className="keni-main_inner">
              <div className="keni-section_wrap">
                <article className="keni-section"><h1 className="section-title">プロフィール</h1>
                  <div className="article-body">
                    <h3>瀬戸美月（通称：美月）</h3>
                    <p /><div className="right"><img src="/images/mizuki.jpg" width={160} height={240} alt="瀬戸美月画像" /></div><p />
                    <p>株式会社わくわくスタディワールド代表取締役。独立系ソフトウェア開発会社，IT系ベンチャー企業でシステム開発，Webサービス立ち上げなどに従事した後独立。<br />
                      企業研修やセミナー，勉強会などで，数多くの受験生を15年以上指導。</p>
                    <p>1971年生まれ。<br />
                      広島県福山市出身。和歌山県田辺市在住。<br />
                      京都大学大学院理学研究科修士課程修了。<br />
                      独立系ソフトウェア開発会社，外資系インターネット関連会社でSEを経験後独立。2007年4月に株式会社わくわくスタディワールド設立。<br />
                      2008年6月に，情報処理技術者試験全15区分制覇。その後もリニューアル中。</p>
                    <h4>執筆書籍一覧</h4>
                    <ul>
                      <li>徹底攻略　基本情報技術者の午後対策　Python編　インプレス<span className="red b">new!</span></li>
                      <li>徹底攻略　情報処理安全確保支援士教科書（共著）　インプレス（平成29年度～）</li>
                      <li>徹底攻略　情報セキュリティマネジメント教科書（共著）　インプレス（平成28年度～）</li>
                      <li>徹底攻略　データベーススペシャリスト教科書　インプレス　（平成26年度～）
                      </li><li>徹底攻略　ネットワークスペシャリスト教科書　インプレス　（平成25年度～）
                      </li><li>徹底攻略　応用情報技術者教科書　インプレス（平成24年度～）
                      </li><li>新　読む講義シリーズ8　システムの構成と方式　アイテック</li>
                      <li>要点整理クイックスタディ　ソフトウェア開発技術者午前問題集　翔泳社</li>
                      <li>インターネット・ネットワーク入門（共著）　アイテック</li>
                      <li>徹底解説本試験問題　応用情報技術者（共著）　アイテック（平成13年～22年）</li>
                      <li>徹底解説本試験問題　データベーススペシャリスト（共著）　アイテック（平成13年～22年）</li>
                      <li>コンピュータシステムの応用知識（共著）　アイテック</li>
                      <li>新版アルゴリズムの基礎（共著）　アイテック</li>
                      <li>システム開発の基礎　第３版（共著）　アイテック</li>
                      <li>完全分析最新過去問題集　基本情報技術者試験（共著）　エクスメディア</li>
                      <li>完全分析最新過去問題集　初級シスアド試験（共著）　エクスメディア</li>
                      <li>基本情報技術者予想問題集（共著）　アイテック</li>
                    </ul>
                    <p>など<br />
                      <font color="red">※著者名を瀬戸稔代で書いているものも含みます。</font>
                    </p>
                    <h3>齋藤　健一（通称：けんけん）</h3>
                    <p /><div className="right"><img src="/images/kenken.jpg" width={160} height={240} alt="齋藤健一画像" /></div><p />
                    <p>株式会社わくわくスタディワールド取締役。食品会社の経営情報企画部で，情報システム導入やセキュリティ管理を10数年にわたり主導。<br />
                      独立後はセキュリティを中心とした指導にあたる傍ら，IT関連や情報処理技術者試験などの動画を中心とした教材作成に携わる。</p>
                    <p>1966年生まれ。<br />
                      東京都世田谷区出身。和歌山県田辺市在住。<br />
                      情報処理技術者試験は，情報セキュリティスペシャリスト，ネットワークスペシャリスト，テクニカルエンジニア（データベース），<br />
                      上級システムアドミニストレータ，情報セキュリティアドミニストレータ，応用情報技術者などを取得。</p>
                    <h4>執筆書籍一覧</h4>
                    <ul>
                      <li>徹底攻略　情報処理安全確保支援士教科書（共著）　インプレス（平成29年度～）<span className="red b">new!</span></li>
                      <li>徹底攻略　情報セキュリティマネジメント教科書（共著）　インプレス（平成28年度～）</li>
                      <li>インターネット・ネットワーク入門（共著）　アイテック</li>
                      <li>徹底解説本試験問題　データベーススペシャリスト（共著）　アイテック（平成13年～22年）</li>
                      <li>完全分析最新過去問題集　基本情報技術者試験（共著）　エクスメディア</li>
                      <li>完全分析最新過去問題集　初級シスアド試験（共著）　エクスメディア</li>
                      <li>基本情報技術者予想問題集（共著）　アイテック</li>
                    </ul>
                    <p>など</p>
                  </div>{/*article-body*/}
                </article>{/*keni-section*/}
              </div>
            </div>{/*keni-main_inner*/}
          </main>{/*keni-main*/}
          {/*▲▲ メインコンテンツ ▲▲*/}
        </div>{/*keni-main_outer*/}
      </div>{/*keni-main_wrap*/}
      </Layout>
  )
}